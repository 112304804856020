var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titAlign": "text-center"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("비밀번호 찾기")]), _c('template', {
    slot: "txt"
  }, [_vm._v("회원가입 시 등록한 휴대폰 번호를 입력해주세요.")]), _c('div', {
    staticClass: "mw-580px mx-auto"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "dense": false,
      "placeholder": "아이디"
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-phone-field', _vm._b({
    attrs: {
      "dense": false
    },
    model: {
      value: _vm.phone,
      callback: function ($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }, 'v-phone-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 min-w-sm-100px",
    attrs: {
      "color": "grey",
      "x-large": ""
    },
    on: {
      "click": _vm.certify
    }
  }, [_vm._v("인증요청")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "rounded-0",
    attrs: {
      "dense": false,
      "type": "number",
      "placeholder": "인증번호를 입력하세요."
    },
    model: {
      value: _vm.otp,
      callback: function ($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.recovery
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs, {
    loading: _vm.loading
  }), false), [_vm._v("비밀번호 찾기")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider-group text-center"
  }, [_c('ul', {
    staticClass: "grey--text"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("로그인")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/login/find-id/"
    }
  }, [_vm._v("아이디 찾기")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/join"
    }
  }, [_vm._v("회원가입")])], 1)])])])], 1)], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }